<template>
    <div class="call-list">
        <div style="height: 100%;box-sizing: border-box;display: flex;flex-direction: column;">
            <div class="calllist-box">
                <div v-if="calllist.length>0">
                    <div v-for="call in calllist" :key="call.recordid" :class="call.disposeresult===0?'callsuccess':''" class="call-box">
                        <div>
                            <div style="display: flex;align-items: center;margin-bottom: 5px;">
                                <span v-if="call.lastname" style="font-size: 16px;margin-right: 5px;">
                                    {{call.lastname}}{{call.sex===0?'女士':'先生'}}
                                </span>
                                <span v-else style="font-size: 16px;margin-right: 5px;">
                                    匿名
                                </span>
                                {{call.phone}}
                            </div>
                            <div class="flexbox phone">
                                <img class="phone-img" style="width: 24px;margin-right: 10px;" src="@/assets/banquet-img/received.png" alt="">
                                {{call.createTime}}
                            </div>
                        </div>
                        <div>
                            <template v-if="call.disposeresult<2">
                                <el-button type="success" plain @click="handleCallRecord(call)" v-if="call.disposeresult===0">处理来电</el-button>
                                <span v-else @click="handleCallRecord(call)" style="cursor: pointer;display: flex;align-items: center;">
                                    已处理
                                    <el-icon><ArrowRightBold /></el-icon>
                                </span>
                            </template>
                            <span v-else>{{call.orderInfo}}</span>
                        </div>
                        <img @click="deleteCall(call.recordid)" class="closecall" style="cursor: pointer;width: 16px;height: 16px;position: absolute;top: 5px;right: 5px;" src="@/assets/close.png" alt="">
                    </div>
                </div>
                <div v-else style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <img style="width: 350px;margin-bottom: 10px;" src="@/assets/empty.png" alt="">
                    暂无来电
                </div>
            </div>
        </div>
        <el-dialog
            v-model="isdelete"
            title="提示"
            width="30%"
        >
            <span>确定要删除吗</span>
            <template #footer>
            <span class="dialog-footer">
                <el-button @click="isdelete = false">取消</el-button>
                <el-button @click="toDelete" type="primary">
                确认
                </el-button>
            </span>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
import {onMounted, ref,nextTick} from 'vue';
// import { Close } from '@element-plus/icons-vue';
import { ArrowRightBold } from '@element-plus/icons-vue'
import { getCallRecord,setCallRecord,deleteCallById } from '@/api/home/home'
import { formatDate } from '@/utils/formatdate'
import { ElMessage } from 'element-plus'
import router from '@/router/router'
import bus from '@/utils/bus'
// import { useRouter } from 'vue-router'
// const router = useRouter();
// const sortlist = ref([{name:'全部',id:0},{name:'已接',id:1},{name:'未接',id:2}]); //排序选择
// const sort = ref(0); //排序
const calllist = ref([]);
const deleteId = ref('');
const isdelete = ref(false);
const nowTime = ref(formatDate('yyyy-MM-dd',new Date()));
const getCallList = ()=>getCallRecord({day:nowTime.value}).then(res=>{
    calllist.value = res.data.data;
}).catch(()=>{});

const handleCallRecord = (data)=>{
    if(data.disposeresult == 0){
        setCallRecord(data.recordid).then(res=>{
            if(res.data.code == 200){
                data.disposeresult = data.disposeresult + 1;
                router.push({path:'/createorder/2',query:{recordId: data.recordid,name:data.fullname,phone:data.phone}})
            }else{
                ElMessage.error(res.data.msg);
            }
        }).catch(()=>{});
    }else if(data.disposeresult == 1){
        router.push({path:'/createorder/2',query:{recordId: data.recordid,name:data.fullname,phone:data.phone}})
    }



}
const deleteCall = (id)=>{
    isdelete.value = true;
    deleteId.value = id;
}
const toDelete = ()=>{
    deleteCallById(deleteId.value).then(res=>{
        if(res.data.code===200){
            ElMessage({
                type:'success',
                message:'删除成功'
            });
            getCallList();
        }else{
            ElMessage({
                type:'error',
                message:res.data.msg
            });
        }
        isdelete.value = false;
    })
}
bus.on('refreshCallList',()=>{
    nextTick(()=>{
        getCallList();
    });

});
// const createOrder = (name,phone)=>{
//     router.push({path:'/createorder/2',query:{name:name,phone:phone}})
// }
onMounted(()=>{
    nextTick(()=>{
        getCallList();
    });
})
</script>
<style scoped>

.call-list{
    height: 100%;
}
.user{
    font-size: 20px;
}
.flexbox{
    display: flex;
    align-items: center;
}
.phone{
    color: #666666;
}
.calllist-box{
    flex: 1;
    overflow: auto;
}
.call-box{
    padding: 10px 10px 5px 30px;
    border-radius: 100px 4px 4px 100px;
    box-shadow: 0px 4px 12px -3px rgba(26, 134, 192, 0.16);
    border: 1px solid #F1F3F6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    position: relative;
    cursor: pointer;
}
.callsuccess{
    background: linear-gradient(270deg, #FFFFFF 0%, #E4FFE4 100%);
}
.closecall{
    display: none;
}
/* 滚动条整体样式 */
.calllist-box::-webkit-scrollbar {
    display: none;
}

/*定义滚动条的轨道颜色、内阴影及圆角*/
.calllist-box::-webkit-scrollbar-track{
    display: none;
}


/*定义滑块颜色、内阴影及圆角*/
.calllist-box::-webkit-scrollbar-thumb{
    display: none;
}
.call-box:hover{
    background: linear-gradient(270deg, #FFFFFF 0%, #DCDCFF 100%);
    
}
.call-box:hover .closecall{
    display: block;
}
.handle{
    background: #F3F3F5;
    padding: 5px 25px;
    border-radius: 2px;
}
.user-info{
    display: flex;
    align-items: center;
    justify-content: end;
}
.user-info span{
    color: #999999;
}

</style>