import {ref} from 'vue';
import bus from '@/utils/bus';
const websocket = ref(null);// websocket 实例
const heartTime = ref(null);// 心跳定时器实例
const socketHeart = ref(0);// 心跳次数
const HeartTimeOut = ref(17000);// 心跳超时时间
const socketError = ref(0);// 错误次数

/**
 * 初始化socket
 */
export function startWebSocket(){
    let user = JSON.parse(localStorage.getItem('user'));
    let url = process.env.VUE_APP_WEBSOCKET_URL+"/"+user.restaurantId;

    websocket.value = new WebSocket(url);
    websocket.value.onopen = function(e){
        console.log('连接 WebSocket 成功',e.returnValue);
        resetHeart();
    };

    websocket.value.onerror = function(){
        console.log('连接 WebSocket 失败');

        setTimeout(()=>{
            reconnect();
        },HeartTimeOut.value);
        
    };
    websocket.value.onmessage = function(e){
        
        let data = e.data;
        if(isJSON(data)){
            let json = JSON.parse(data);
            if(json.type === 'heartbeat'){
                // console.log("心跳");
                resetHeart();
            }else{
                executeSource(json);
            }
        }else{
            // console.log('WebSocket 接收的消息：'+data)
        }
    }
}
/**
 * 判断字符串是否符合json格式
 * @param {*} str 
 * @returns 
 */
const isJSON=(str)=>{
    if(typeof str == 'string'){
        try{

            var obj = JSON.parse(str)
            if(typeof obj == 'object' && obj){
                return true;
            }else{
                return false;
            }
        }catch(e){
            return false;
        }
    }
}
/**
 * 监控事件
 * @param {} event 
 */
const executeSource = (event) =>{
    if(event.eventSource !== undefined){
        // console.log("事件来源："+event.eventSource);

        bus.emit('listenWebSocket',event);
        bus.emit('orderListRefresh',event);
    }
}

/**
 * socket 重置心跳
 */
const resetHeart = ()=>{
    socketHeart.value = 0;
    socketError.value = 0;
    clearInterval(heartTime.value);
    sendSocketHeart();
}
export function stopWebSocket(){
    socketHeart.value = 0;
    socketError.value = 0;
    clearInterval(heartTime.value);
    websocket.value = null;

}
/**
 * socket心跳发送
 */
const sendSocketHeart = ()=>{
    heartTime.value = setInterval(()=>{
        // 如果连接正常则发送心跳
        if(websocket.value.readyState == 1){
            // console.log('心跳发送：',socketHeart.value);
            websocket.value.send(
                JSON.stringify({
                    type: "ping"
                })
            );
            socketHeart.value = socketHeart.value + 1;
        }else{
            //重连
            reconnect();
        }
    },HeartTimeOut.value);
};

/**
 * socket 重连
 */
const reconnect = () => {
    clearInterval(heartTime.value);
    startWebSocket();
    socketError.value = socketError.value + 1;
    console.log("websocket重连",socketError.value);
}


