const state = {
    date:'',
    mealtype:'',
    desklist:[],
    lunar:'',
    cancelTokenArr:[],
    canselectdesk:true,
}
const mutations = {
    changeDate(state,date){
        state.date = date
    },
    changeMealtype(state,mealtype){
        state.mealtype = mealtype
    },
    changeDesklist(state,desklist){
        state.desklist = desklist
    },
    changeLunar(state,lunar){
        state.lunar = lunar
    },
    addCancelToken(state,cancel){
        if(!state.cancelTokenArr){
            state.cancelTokenArr = []
        }
        if(cancel){
            state.cancelTokenArr.push(cancel)
        }
    },
    clearCancelToken(state){
        state.cancelTokenArr.forEach(c=>{
            if(c){
                c()
            }
        })
        state.cancelTokenArr=[]
    },
    canselectdesk(state,data){
        state.canselectdesk = data
    }
}
const actions={
    changeDate(context,date){
        context.commit('changeDate',date)
    },
    changeMealtype(context,mealtype){
        context.commit('changeMealtype',mealtype)
    },
    changeDesklist(context,desklist){
        context.commit('changeDesklist',desklist)
    },
    changeLunar(context,lunar){
        context.commit('changeLunar',lunar)
    },
    canselectdesk(context,data){
        context.commit('canselectdesk',data)
    }
}
export default{
    state,
    mutations,
    actions
}