// 格式化日期
export const formatDate = (format,d) => {
    let date = new Date(d);
    var o = {
        "Y+" : date.getFullYear(),                //年
        "M+" : date.getMonth()+1,                 //月
        "d+" : date.getDate(),                    //日 
        "h+" : date.getHours(),                   //时 
        "m+" : date.getMinutes(),                 //分 
        "s+" : date.getSeconds(),                 //秒 
    }; 
    if(/(y+)/.test(format)) {
        format=format.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length)); 
    }
    for(let k in o) {
        if(new RegExp("("+ k +")").test(format)){
            format = format.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        }
    }
    return format;
}
