import api from '@/utils/request'
import { parseStrEmpty } from "@/utils/ruoyi/ruoyi";
import { Buffer } from 'buffer';
// 查询用户列表
export function listUser(query) {
  return api().get('/web/user/list',{params: query})
}

// 查询用户详细
export function getUser(userId) {
  return api().get('/web/user/' + parseStrEmpty(userId),)
}

// 新增用户
export function addUser(data) {
  data.password = Buffer.from(data.password).toString('base64');
  return api.post('/web/user/add',data)
}

// 修改用户
export function updateUser(data) {
  data.password = Buffer.from(data.password).toString('base64');
  return api.put('/web/user',data)
}

// 修改密码
export function changePassword(data){
  data.password = Buffer.from(data.password).toString('base64');
  return api.post('/web/user/changePassword',data)
}
// 删除用户
export function delUser(userId) {
  return api().delete('/web/user/' + userId)
}

// 用户密码重置
export function resetUserPwd(userId, password) {
  let passwd = Buffer.from(password).toString('base64');

  const data = {
    userId: userId,
    password: passwd
  }
  return api.put('/web/user/resetPwd',data)
}

// 用户状态修改
export function changeUserStatus(userId, status) {
  const data = {
    userId,
    status
  }
  return api.put('/web/user/changeStatus',data)
}

// 查询用户个人信息
export function getUserProfile() {
  return api().get('/web/user/profile')
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return api.put('/web/user/profile',data)
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return api.put('/web/user/profile/updatePwd',data)
}

// 用户头像上传
export function uploadAvatar(data) {
  return api.post('/web/user/profile/avatar',data)
}

// 查询授权角色
export function getAuthRole(userId) {
  return api().get('/web/user/authRole/' + userId,)
}

// 保存授权角色
export function updateAuthRole(data) {
  return api.put('/web/user/authRole',data)
}
// 获取当前用户餐厅列表
export function getRestaurantList_api(){
  return api().get('/web/user/getRestaurantList')
}