import api from '@/utils/request'

// 查询参数列表
export function listConfig(query) {
  return api().get('/web/config/list',{params: query})
}

// 查询参数详细
export function getConfig(configId) {
  return api().get('/web/config/' + configId)
}

// 根据参数键名查询参数值
export function getConfigKey(configKey) {
  return api().get('/web/config/configKey/' + configKey)
}

// 新增参数配置
export function addConfig(data) {
  return api.post('/web/config',data)
}

// 修改参数配置
export function updateConfig(data) {
  return api.put('/web/config',data)
}

// 删除参数配置
export function delConfig(configId) {
  return api().delete('/web/config/' + configId)
}

// 刷新参数缓存
export function refreshCache() {
  return api().delete('/web/config/refreshCache')
}
// 查询词典表
export function getQueryData(dictType) {
  return api().get('/app/customer/queryDictData?dictType=' + dictType)
}
