<!--
 * @Author: yanjinzhong
 * @Date: 2022-02-12 18:08:48
 * @LastEditors: yanjinzhong
 * @LastEditTime: 2022-02-20 17:45:21
-->
<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'app',
};
</script>

<style>

html,
body,
#app {
	height: 100%;
	padding: 0;
	margin: 0;
	font-size: 14px;
	font-family: Microsoft YaHei;
    user-select: none;
	min-width: 1366px;
}
#app {
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

</style>
