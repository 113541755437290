import axios from 'axios';
import store from '@/store';
const config = {
	baseURL: process.env.VUE_APP_URL,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
		Authorization: localStorage.getItem('token'),
	},
};
const token = localStorage.getItem('token');
if (token) {
	config.headers['Authorization'] = token;
}
// 设置token
// const api = axios.create(config);
const api = ()=>{
	const config_url = {
		baseURL: process.env.VUE_APP_URL,
		timeout:10000,
		headers: {
			'Content-Type': 'application/json;charset=utf-8',
			Authorization: localStorage.getItem('token')??'',
		},
	};
	const request = axios.create(config_url);
	request.interceptors.request.use(config => {
		config.cancelToken = new axios.CancelToken(cancel=>{
			store.commit('addCancelToken',cancel)
		})
		return config;
	})
	return request;
}
//封装下post
api.post = function (url, params,type) {
	const token = localStorage.getItem('token');
	return new Promise((resolve, reject) => {
		axios({
			method: 'post',
			url: config.baseURL + url,
			data: params,
			responseType:type?type:'json',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
				Authorization: token ?? '',
			},
		}).then((response) => {
			if (response.status == 200) {
				resolve(response);
			} else {
				reject(response);
			}
		});
	});
};
//封装下put
api.put = function (url, params) {
	const token = localStorage.getItem('token');
	return new Promise((resolve, reject) => {
		axios({
			method: 'put',
			url: config.baseURL + url,
			data: params,
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
				Authorization: token ?? '',
			},
		}).then((response) => {
			if (response.status == 200) {
				resolve(response);
			} else {
				reject(response);
			}
		});
	});
};
api.delete = function(url) {
	const token = localStorage.getItem('token');
	return new Promise((resolve, reject) => {
		axios({
			method: 'delete',
			url: config.baseURL + url,
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
				Authorization: token ?? '',
			},
		}).then((response) => {
			if (response.status == 200) {
				resolve(response);
			} else {
				reject(response);
			}
		});
	});
};
api.logout = function(){
	const token = localStorage.getItem('token');
	return new Promise((resolve, reject) => {
		axios({
			method: 'delete',
			url: config.baseURL + '/auth/logout',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
				Authorization: token ?? '',
			},
		}).then((response) => {
			if (response.status == 200) {
				localStorage.clear();
				
				resolve(response);
			} else {
				reject(response);
			}
		});
	});
};
// http response 拦截器
api().interceptors.response.use(
	(response) => {
		// 请求成功对响应数据做处理，此处返回的数据是axios.then(res)中接收的数据
		if (response.code ==0 || response.code == 200) {
			// code值为 0 或 200 时视为成功
			return Promise.resolve(response)
		}
		// 拦截响应，做统一处理
		if (response.data&&response.data.code) {
			switch (response.data.code) {
				case 401:
					api.logout();
			}
		}
		return Promise.reject(response)
	},
	(error) => {
		return Promise.reject(error.response.status); // 返回接口返回的错误信息
	}
);

export default api;
