import api from '@/utils/request'

// 查询角色列表
export function listRole(query) {
  return api().get('/web/role/list',{params: query})
}
// 查询角色权限接口
export function getPriviledgeByRestaurant(params){
  return api.post('/web/priviledge/getPriviledgeByRestaurant',params)
}
// 保存角色权限接口
export function savePriviledgeByRole(params){
  return api.post('/web/priviledge/savePriviledgeByRole',params)
}

// 获取餐厅流程
export function getUIProcessByRestaurant(params){
  return api.post('/web/priviledge/getUIProcessByRestaurant',params)
}
// 保存餐厅流程
export function saveUIProcessByRestaurant(params){
  return api.post('/web/priviledge/saveUIProcessByRestaurant',params)
}
// 查询角色权限接口
export function getPriviledgeAndPocessByUser(params){
  return api.post('/web/priviledge/getPriviledgeAndPocessByUser',params)
}