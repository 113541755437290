/*
 * @Author: yanjinzhong
 * @Date: 2022-02-15 11:17:10
 * @LastEditors: yanjinzhong
 * @LastEditTime: 2022-03-03 11:19:41
 */
import { createRouter, createWebHashHistory } from 'vue-router';

import PageMain from '@/components/pageMain';
import PageHome from '@/components/pageHome'
import store from '@/store';

const constantRoutes = [
	{
		path: '/login',
		name: 'login',
		meta:{
			name:'login'
		},
		component: () => import('@/views/login/login-page'),
	},
	{
		path: '',
		name: 'main',
		redirect: '/index',
		component: PageMain,
		children: [
			{
				path: '/index',
				component: PageHome,
				name: 'index',
				children:[
					{
						path: '/index',
						name:'index',
						component: ()=>import("@/views/order/orderList")
					},
					{
						path:'/call',
						name:'call',
						component: ()=>import('@/views/order/callList')
					},
					{
						path:'/todayorder',
						name:'todayorder',
						component: ()=>import('@/views/order/todayOrder')
					},
					{
						path:'/createorder/:id',
						name:'createorder',
						component: ()=>import('@/views/order/createOrder')
					},
					{
						path:'/settable/:id',
						name:'settable',
						component: ()=>import('@/views/order/SetTable')
					},
					{
						path:'/nuclearmeal/:id',
						name:'nuclearmeal',
						component: ()=>import('@/views/order/NuclearMeal')
					},
					
					{
						path:'/guestarrival/:id',
						name:'guestarrival',
						component: ()=>import('@/views/order/GuestArrival')
					},
					{
						path:'/removetable/:id',
						name:'removetable',
						component: ()=>import('@/views/order/RemoveTable')
					},
					{
						path:'/addtable/:id',
						name:'addtable',
						component: ()=>import('@/views/order/AddTable')
					},
					{
						path:'/changetable/:id',
						name:'changetable',
						component: ()=>import('@/views/order/ChangeTable')
					},
					{
						path:'/printOrder',
						name:'printOrder',
						component: ()=>import('@/views/order/printOrder')
					}
				]
			},
			{
				path:'/noticelist',
				component: ()=>import('@/views/order/orderNoticeList')
			},
			{
				path:'/cancellationlist',
				component: ()=>import('@/views/order/CancellationList.vue')
			},
			{
				path:'lockTable',
				component: ()=>import('@/views/order/LockTable')
			},
			{
				path:'unlockTable',
				component: ()=>import('@/views/order/UnlockTable')
			},
			{
				path:'/historyNoticelist',
				component: ()=>import('@/views/order/historyOrder')
			},
			{
				path:'/sales',
				component: ()=>import('@/views/customer/sales')
			},
			{
				path:'/customer',
				component: ()=>import('@/views/customer/customer')
			},
			{
				path:'/case',
				component: ()=>import('@/views/customer/case')
			},
			{
				path:'/ranking',
				component: ()=>import('@/views/customer/ranking')
			},
			{
				path:'/order',
				component: ()=>import('@/views/customer/order')
			},
			{
				path:'/entryOrder',
				component: ()=>import('@/views/order/entryOrder')
			},
			{
				path:'/user',
				name:'user',
				component:	()=>import('@/views/user/index'),
			},
			{
				path:'/dept',
				name:'dept',
				component: ()=>import('@/views/dept/index'),
			},
			{
				path:'/role',
				name:'role',
				component: ()=>import('@/views/role/role-index'),
			},
			{
				path:'/optimize',
				name:'optimize',
				component: ()=>import('@/views/optimize/flow-path'),
			},
			{
				path: '/setLocation',
				name: 'setLocation',
				meta: {
					name: 'setLocation',
				},
				component: () => import('@/views/booking/setLocation'),
			},
			{
				path: '/setTimes',
				name: 'setTimes',
				meta: {
					name: 'setTimes',
				},
				component: () => import('@/views/booking/setTimes'),
			},
			{
				path: '/setType',
				name: 'setType',
				meta: {
					name: 'setType',
				},
				component: () => import('@/views/booking/setType'),
			},
			{
				path:'/goodDay',
				name:'goodDay',
				meta:{
					name:'goodDay',
				},
				component: () => import('@/views/booking/goodDay'),
			},
		],
	},
	{
		path:'/customer',
		component:PageMain,
		redirect:'/customer/list',
		children:[
			{
				path:'/customer/list',
				name:'customer-list',
				meta:{
					name:'customer-list'
				},
				component: () => import('@/views/customer/customerList.vue')
			}
		]
	},
	{
		path: '/business',
		component: PageMain,
		redirect: '/business/label',
		children: [
			{
				path: '/business/label',
				name: 'business-label',
				meta: {
					name: 'business-label',
				},
				component: () => import('@/views/business/business-label'),
			},
			{
				path: '/business/financial',
				name: 'business-financial',
				meta: {
					name: 'business-financial',
				},
				component: () => import('@/views/business/business-financial'),
			},
			{
				path: '/business/dishes',
				name: 'business-dishes',
				meta: {
					name: 'business-dishes',
				},
				component: () => import('@/views/business/dishes/business-dishes'),
			},
			{
				path: '/business/package',
				name: 'business-package',
				meta: {
					name: 'business-package',
				},
				component: () => import('@/views/business/package/business-package'),
			},
			{
				path: '/business/customers',
				name: 'business-customers',
				meta: {
					name: 'business-customers',
				},
				component: () => import('@/views/business/business-customers.vue'),
			},
		],
	},
	{
		path: '/booking',
		name: 'booking',
		redirect: '/booking/restaurant',
		component: PageMain,
		children: [
			{
				path: '/booking/add/:id',
				name: 'booking-add',
				meta: {
					name: 'booking-add',
				},
				component: () => import('@/views/booking/booking-add'),
			},
			{
				path: '/booking/restaurant',
				name: 'booking-restaurant',
				meta: {
					name: 'booking-restaurant',
				},
				component: () => import('@/views/booking/restaurant-information'),
			},
		],
	},
	{
		path: '/message',
		name: 'message',
		redirect: '/message/setmessage',
		component: PageMain,
		children: [
			{
				path: '/message/setmessage',
				name: 'setmessage',
				meta: {
					name: 'setmessage',
				},
				component: () => import('@/views/message/SetMessage'),
			},
			{
				path: '/message/messagelist',
				name: 'messagelist',
				meta: {
					name: 'messagelist',
				},
				component: () => import('@/views/message/MessageList'),
			},
			{
				path: '/message/mesrecord',
				name: 'mesrecord',
				meta: {
					name: 'mesrecord',
				},
				component: () => import('@/views/message/MesRecord'),
			},
			{
				path: '/message/mesrecharge',
				name: 'mesrecharge',
				meta:{
					name:'mesrecharge',
				},
				component: () => import('@/views/message/MesRecharge'),
			}
		],
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes: constantRoutes,
});

router.beforeEach((to)=>{
	store.commit('clearCancelToken')
	const token = localStorage.getItem('token');
	if (to.meta.name!=='login') {
		if (!token) {
			return router.replace({
				name: 'login'
			})
		}
	}
	// next();
})

export default router;
